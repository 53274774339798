@import "./../../../scss/variables";

$left-row: 232px;


.FormRequest{
  height: 100%;

  &__Container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 52px 40px 82px;
    min-width: $min-width;
    min-height: 100%;

    @media (max-width: $media-mobile) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__Header {
    font-size: 30px;
    font-weight: bold;
    color: $color-purple;
    text-align: center;
    margin: 0 0 32px;
    padding-left: $left-row;
    font-family: 'Rubik', Arial, sans-serif;

    @media (max-width: $media-tablet) {
      padding-left: 0;
    }
  }

  &__Chapter {
    margin-bottom: 30px;
    display: flex;

    @media (max-width: $media-tablet) {
      flex-direction: column;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__Lefts {
    flex: 0 0 $left-row;

    @media (max-width: $media-tablet) {
      flex: 0 0 auto;
    }
  }

  &__Title {
    padding-top: 20px;
    max-width: 207px;
    font-size: 33px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    color: $color-black;
    opacity: 0.15;
    position: sticky;
    padding-bottom: 20px;
    top: 0;
    font-family: 'Rubik', Arial, sans-serif;

    @media (max-width: $media-tablet) {
      max-width: none;
      position: relative;
      text-align: center;
      padding-top: 0;
      font-size: 28px;
    }
  }

  &__Rights {
    padding: 30px 48px 38px;
    border-radius: 10px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.12);
    background-color: $color-white;
    flex: 1 1 auto;

    @media (max-width: $media-mobile) {
      box-shadow: none;
      border-radius: 0;
      position: relative;
      padding: 30px 0 14px;

      &:before {
        content: '';
        background: $color-white;
        left: 50%;
        top: 0;
        height: 100%;
        width: 100vw;
        margin-left: -50vw;
        position: absolute;
      }
    }
  }

  &__Row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: $media-mobile) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__Column {
    width: calc(50% - 24px);
    flex-grow: 0;
    flex-shrink: 0;

    @media (max-width: $media-mobile) {
      width: 100%;
      margin-bottom: 24px;

      &:empty {
        display: none;
      }
    }

    &:only-child {
      flex-grow: 1;
    }
  }

  &__CommentShow {
    margin-top: 46px;
    text-align: center;
    padding-left: $left-row;

    @media (max-width: $media-tablet) {
      padding-left: 0;
    }
  }

  &__CommentShowLink {
    font-size: 15px;
    font-weight: 500;
    color: $color-purple;
    cursor: pointer;
    text-decoration: underline;
    transition: all .1s ease-in;

    &:active,
    &:focus {
      filter: none;
    }

    &:hover {
      filter: brightness(1.2);
    }
  }

  &__Button {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    padding-left: $left-row;

    @media (max-width: $media-tablet) {
      padding-left: 0;
    }
  }

}