
@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-BlackItalic.eot');
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'),
  url('./../fonts/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-BlackItalic.woff') format('woff'),
  url('./../fonts/Rubik-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-BoldItalic.eot');
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
  url('./../fonts/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-BoldItalic.woff') format('woff'),
  url('./../fonts/Rubik-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Light.eot');
  src: local('Rubik Light'), local('Rubik-Light'),
  url('./../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-Light.woff') format('woff'),
  url('./../fonts/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Medium.eot');
  src: local('Rubik Medium'), local('Rubik-Medium'),
  url('./../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-Medium.woff') format('woff'),
  url('./../fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Italic.eot');
  src: local('Rubik Italic'), local('Rubik-Italic'),
  url('./../fonts/Rubik-Italic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-Italic.woff') format('woff'),
  url('./../fonts/Rubik-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Bold.eot');
  src: local('Rubik Bold'), local('Rubik-Bold'),
  url('./../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-Bold.woff') format('woff'),
  url('./../fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-LightItalic.eot');
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'),
  url('./../fonts/Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-LightItalic.woff') format('woff'),
  url('./../fonts/Rubik-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Regular.eot');
  src: local('Rubik'), local('Rubik-Regular'),
  url('./../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-Regular.woff') format('woff'),
  url('./../fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-MediumItalic.eot');
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'),
  url('./../fonts/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-MediumItalic.woff') format('woff'),
  url('./../fonts/Rubik-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik Mono One';
  src: url('./../fonts/RubikMonoOne-Regular.eot');
  src: local('Rubik Mono One Regular'), local('RubikMonoOne-Regular'),
  url('./../fonts/RubikMonoOne-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/RubikMonoOne-Regular.woff') format('woff'),
  url('./../fonts/RubikMonoOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Black.eot');
  src: local('Rubik Black'), local('Rubik-Black'),
  url('./../fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Rubik-Black.woff') format('woff'),
  url('./../fonts/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Bold.eot');
  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
  url('./../fonts/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Bold.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Bold.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-ThinItalic.eot');
  src: local('Avenir Next Cyr Thin Italic'), local('AvenirNextCyr-ThinItalic'),
  url('./../fonts/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-ThinItalic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-ThinItalic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-MediumItalic.eot');
  src: local('Avenir Next Cyr Medium Italic'), local('AvenirNextCyr-MediumItalic'),
  url('./../fonts/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-MediumItalic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-MediumItalic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('./../fonts/AvenirNextCyr-UltraLightIt.eot');
  src: local('Avenir Next Cyr Ultra Light Italic'), local('AvenirNextCyr-UltraLightIt'),
  url('./../fonts/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-UltraLightIt.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-UltraLightIt.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Light.eot');
  src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
  url('./../fonts/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Light.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Light.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Italic.eot');
  src: local('Avenir Next Cyr Italic'), local('AvenirNextCyr-Italic'),
  url('./../fonts/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Italic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Italic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-BoldItalic.eot');
  src: local('Avenir Next Cyr Bold Italic'), local('AvenirNextCyr-BoldItalic'),
  url('./../fonts/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-BoldItalic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-BoldItalic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Heavy.eot');
  src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
  url('./../fonts/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Heavy.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Heavy.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-HeavyItalic.eot');
  src: local('Avenir Next Cyr Heavy Italic'), local('AvenirNextCyr-HeavyItalic'),
  url('./../fonts/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-HeavyItalic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-HeavyItalic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Thin.eot');
  src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'),
  url('./../fonts/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Thin.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Thin.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
  url('./../fonts/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Medium.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-DemiItalic.eot');
  src: local('Avenir Next Cyr Demi Italic'), local('AvenirNextCyr-DemiItalic'),
  url('./../fonts/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-DemiItalic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-DemiItalic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-DemiItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Demi.eot');
  src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
  url('./../fonts/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Demi.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-Regular.eot');
  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
  url('./../fonts/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-Regular.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('./../fonts/AvenirNextCyr-LightItalic.eot');
  src: local('Avenir Next Cyr Light Italic'), local('AvenirNextCyr-LightItalic'),
  url('./../fonts/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-LightItalic.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-LightItalic.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('./../fonts/AvenirNextCyr-UltraLight.eot');
  src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'),
  url('./../fonts/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/AvenirNextCyr-UltraLight.woff2') format('woff2'),
  url('./../fonts/AvenirNextCyr-UltraLight.woff') format('woff'),
  url('./../fonts/AvenirNextCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}