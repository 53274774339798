$color-bg: #fafafa;
$color-purple: #6f6b9e;
$color-black: #0f0f0f;
$color-black-medium: #0f0f0f50;
$color-error: #cd5c5d;
$color-white: #fff;


$min-width: 360px;
$max-width: 1200px;
$max-width-mobile: 480px;

$media-mobile: 767px;
$media-tablet: 1199px;