@import "./../../../../scss/variables";

.Input {
  &__Label {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
    margin-bottom: 9px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__Field {
    min-height: 38px;
    padding: 0 12px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: solid 1px rgba(151, 151, 151, 0.35);
    background-color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    color: $color-black;
    outline: none;
    transition: .15s ease-in;
    transition-property: box-shadow, border-color;
    font-family: 'Avenir Next Cyr', Arial, sans-serif;

    .css-field-empty & {
      color: $color-black-medium;
    }

    &:active,
    &:focus {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    }
  }

  &__Error {
    color: #cd5c5d;
    display: none;
    font-size: 13px;
    font-weight: 500;
    margin-top: 4px;
  }

  &_errored &__Field {
    border-color: $color-error;
  }

  &_errored &__Error {
    display: block;
  }
}