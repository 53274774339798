@import "./../../../scss/variables";

.Error404{
  &__Container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 52px 40px 112px;
    min-width: $min-width;

    @media (max-width: $media-mobile) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__Header {
    font-size: 30px;
    font-weight: bold;
    color: $color-purple;
    text-align: center;
    margin: 0 0 32px;
    font-family: 'Rubik', Arial, sans-serif;

    @media (max-width: $media-tablet) {
      padding-left: 0;
    }
  }

  &__Text {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}