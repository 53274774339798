@import "./../../../../scss/variables";

.Button {
  display: flex;
  min-height: 64px;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  background: $color-purple;
  color: $color-white;
  border-radius: 36px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.13);
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  border-width: 0;
  outline-width: 0;
  font-family: 'Avenir Next Cyr', Arial, sans-serif;
  transition: all .1s ease-in;
  position: relative;
  cursor: pointer;

  &:active,
  &:focus {
    filter: none;
  }

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    top: 1px;
    left: 1px;
  }
}