@import "./variables";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  outline: none;
  font-weight: normal;
  font-style: normal;
  font-family: 'Avenir Next Cyr', Arial, sans-serif;
  background: $color-bg;
}

html {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

a {
  -webkit-tap-highlight-color: transparent;

  &:focus,
  &:active {
    text-decoration: none;
    outline-width: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

#root {
  height: 100%;
}